.container {
  width: 120px;
  height: 180px;
  background-color: lightskyblue !important;
  display: inline-flex;
  margin: 20px;
  padding: 10px;
  padding-right: 20px;
  font-size: 36px;
  user-select: none;
}

.labelContent {
  width: 100%;
  height: 100%;
  text-align: center;
}

.textFitContainer {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
