.tilesContainer {
  display: flex;
}

.header {
  font-size: 16px;
  color: gray;
  text-transform: uppercase;
  margin-left: 20px;
}

.tileAlikeSpinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 180px;
  margin: 20px;
  padding: 10px;
  padding-right: 20px;
}
